import React from "react";
import Router from "./router/Router";
import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/magnific-popup.css'
import './assets/fontawesome/css/all.min.css'
import './assets/css/dripicons.css'
import './assets/css/slick.css'
import './assets/css/default.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

function App() {
  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
}

export default App;
