import React from 'react'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import DFitContainer from '../components/DFitContainer'
import '../../node_modules/photoswipe/dist/photoswipe.css'

function DFit() {
  return (
    <React.Fragment>
      <Helmet>
        <title>D-FIT - SAB</title>
      </Helmet>

      <Header />

      <DFitContainer />

      <Footer />
    </React.Fragment>
  )
}

export default React.memo(DFit)
