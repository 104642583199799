import React from 'react'
import About from './About'
import Speakers from './Speakers'
import ProgramsEvent from './ProgramsEvent'
import Sponsors from './Sponsors'
import BlogList from './BlogList'
import BannerCountArea from './BannerCountArea'
import BgVideo from './BgVideo'
import Pricing from './Pricing'
import PartnersContainer from './PartnersContainer'


function HomeContainer() {
    return (
        <main>
            {/* <section 
                id="parallax" 
                class="slider-area slider-bg second-slider-bg slider-bg3 d-flex align-items-center justify-content-center fix" 
                style={{background: "url(../../public/assets/video/SAB 2023.mp4)", objectFit: 'cover'}}
            >
                <div class="slider-shape ss-one layer" data-depth="0.10"><img src="/assets/img/doddle_6.png" alt="shape" /></div>
                <div class="slider-shape ss-three layer" data-depth="0.40"><img src="/assets/img/doddle_9.png" alt="shape" /></div>
                <div class="slider-shape ss-four layer" data-depth="0.60"><img src="/assets/img/doddle_7.png" alt="shape" /></div>
				<div class="slider-active">
					<div class="single-slider">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12">
                                    <div class="slider-content second-slider-content">
                                        <ul data-animation="fadeInUp animated" data-delay=".2s">
											<li><i class="fas fa-map-marker-alt"></i> STARLAND Hotel, Douala</li>
											<li><i class="far fa-clock"></i>  7 - 10 Novembre 2024, </li>
											<li><i class="fal fa-building"></i>2°  Edition </li>
										</ul>
                                        <h2 data-animation="fadeInUp animated" data-delay=".4s">Salon de l'Alimentation et du Bien-être(SAB)</h2> 
										<div class="conterdown wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s" countdown data-date="Nov 7 2024 08:00:00">
										 <div class="timer">										 
											<div class="timer-outer bdr1">												
											   <span class="days" data-days>170</span> 
											   <div class="smalltext">Days</div>
											   <div class="value-bar"></div>
											</div>
											<div class="timer-outer bdr2">
											   <span class="hours" data-hours>0</span> 
											   <div class="smalltext">Hours</div>
											</div>
											<div class="timer-outer bdr3">
											   <span class="minutes" data-minutes>0</span> 
											   <div class="smalltext">Minutes</div>
											</div>
											<div class="timer-outer bdr4">
											   <span class="seconds" data-seconds>0</span> 
											   <div class="smalltext">Seconds</div>
											</div>
											<p id="time-up"></p>
										 </div>
										</div>
                                    </div>
                                </div>
								<div class="col-lg-4 col-sm-12">
									<div class="booking-form mt-50 align-items-center justify-content-center wow fadeInLeft" data-animation="fadeInLeft" data-delay=".2s">
										<h2>S'enregistrer dès maintenant !</h2>
										<form>
											<div class="form-outer">
												<div class="icon"><i class="fal fa-user"></i></div>
												<input type="text" placeholder="Entrer votre nom"/>
											</div>
											<div class="form-outer">
												<div class="icon"><i class="fal fa-envelope"></i></div>
												<input type="email" placeholder="Entrer votre email"/>
											</div>
											<div class="form-outer">
												<div class="icon"><i class="far fa-phone"></i></div>
												<input type="text" placeholder="Entrer votre téléphone"/>
											</div>
											<div class="form-outer">
												<div class="icon"><i class="fal fa-list"></i></div>
												<select id="select">
												   <option selected="selected">Combien seriez-vous ?</option>
												   <option value={1}>1</option>  
												   <option value={2}>2</option>  
												   <option value={3}>3</option>  
												   <option value={4}>4</option>  
												   <option value={5}>5</option>  
												</select>										
											</div>
											<div class="text-center">
												<a href="/" class="btn"><i class="far fa-ticket-alt"></i>S'inscrire</a>
											</div>
										</form>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
			
			<div className='video-background'>
				<video autoPlay loop muted>
					<source src="/assets/video/Montage.mp4" type='video/mp4'/>
				</video>
			</div>

			<div className="container mt-4 pt-20">
				<div class="row">
					<div class="col-lg-12 col-sm-12">
						<div class="slider-content second-slider-content">
							<ul data-animation="fadeInUp animated" data-delay=".2s">
								<li style={{color: 'black'}}><i class="fas fa-map-marker-alt"></i> STARLAND Hotel, Douala</li>
								<li style={{color: 'black'}}><i class="far fa-clock"></i>  7 - 10 Novembre 2024 </li>
								<li style={{color: 'black'}}><i class="fal fa-building"></i>2°  Edition </li>
							</ul>
							<h2 style={{color: '#118A3C'}} class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Salon de l'Alimentation et du Bien-être(SAB)</h2>
							{/* <div class="conterdown wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s" countdown data-date="Nov 7 2024 08:00:00">
								<div class="timer">										 
								<div class="timer-outer bdr1">												
									<span class="days" data-days>170</span> 
									<div class="smalltext">Days</div>
									<div class="value-bar"></div>
								</div>
								<div class="timer-outer bdr2">
									<span class="hours" data-hours>0</span> 
									<div class="smalltext">Hours</div>
								</div>
								<div class="timer-outer bdr3">
									<span class="minutes" data-minutes>0</span> 
									<div class="smalltext">Minutes</div>
								</div>
								<div class="timer-outer bdr4">
									<span class="seconds" data-seconds>0</span> 
									<div class="smalltext">Seconds</div>
								</div>
								<p id="time-up"></p>
								</div>
							</div> */}
						</div>
					</div>
					{/* <div class="col-lg-4 col-sm-12">
						<div class="booking-form mt-50 align-items-center justify-content-center wow fadeInLeft" data-animation="fadeInLeft" data-delay=".2s">
							<h2>S'enregistrer dès maintenant !</h2>
							<form>
								<div class="form-outer">
									<div class="icon"><i class="fal fa-user"></i></div>
									<input type="text" placeholder="Entrer votre nom"/>
								</div>
								<div class="form-outer">
									<div class="icon"><i class="fal fa-envelope"></i></div>
									<input type="email" placeholder="Entrer votre email"/>
								</div>
								<div class="form-outer">
									<div class="icon"><i class="far fa-phone"></i></div>
									<input type="text" placeholder="Entrer votre téléphone"/>
								</div>
								<div class="form-outer">
									<div class="icon"><i class="fal fa-list"></i></div>
									<select id="select">
										<option selected="selected">Combien seriez-vous ?</option>
										<option value={1}>1</option>  
										<option value={2}>2</option>  
										<option value={3}>3</option>  
										<option value={4}>4</option>  
										<option value={5}>5</option>  
									</select>										
								</div>
								<div class="text-center">
									<a href="/" class="btn"><i class="far fa-ticket-alt"></i>S'inscrire</a>
								</div>
							</form>
						</div>
					</div> */}
				</div>
			</div>
            
            <About />
            
            {/* <Speakers /> */}
            
            {/* <!-- counter-area --> */}
            <BannerCountArea />
            
			{/* <!-- event --> */}
            <ProgramsEvent />
            
			{/* <!-- pricing-area --> */}
            <Pricing />

			{/* Partners Description container */}
			<PartnersContainer />

			{/* Devenez sponsor */}
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8">
					<div class="section-title text-center mb-80">
						<span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Devenez Sponsor</span>
						<p class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Que ce soit pour renouveler des amitiés, développer des réseaux professionnels ou affirmer vos 
						engagements sociétaux, nos programmes, taillés sur mesure sauront répondre à vos désirs les plus 
						inavoués. Contactez-nous et recevez une copie du dossier marketing du SAB AFRIQUE 2024.
						</p>
					</div>
				</div>
			</div>

			{/* Devenez partenaire */}	
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8">
					<div class="section-title text-center mb-80">
						<span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Devenez Partenaire</span>
						<p class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Pour sa deuxième édition au sein du triangle national, le SAB AFRIQUE lance un appel à partenariats 
							adressé aux entreprises, institutions, particuliers etc. souhaitant nourrir notre ambition de faire vibrer 
							l’Afrique en dialogue avec d’autres cultures, pour un impact sociétal durable contribuant à la valorisation 
							des écosystèmes de l’Alimentation et du Bien- Être
						</p>
					</div>
				</div>
			</div>
            
            {/* <!-- Sponsors-area --> */}
            <Sponsors />
           
            {/* <!-- blog-area --> */}
            {/* <BlogList /> */}
        </main>
    )
}

export default HomeContainer
