import React, { useState } from 'react'
import Banner from './Banner'
import { Modal } from 'react-bootstrap';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const images = [
    "/assets/img/2023/Orphelinat/image0.jpeg",
    "/assets/img/2023/Orphelinat/image1.jpeg",
    "/assets/img/2023/Orphelinat/image2.jpeg",
    "/assets/img/2023/Orphelinat/image3.jpeg",
    "/assets/img/2023/Orphelinat/image4.jpeg",
    "/assets/img/2023/Orphelinat/image5.jpeg",
    "/assets/img/2023/Orphelinat/image6.jpeg",
    "/assets/img/2023/Orphelinat/image7.jpeg",
    "/assets/img/2023/Orphelinat/image8.jpeg",
    "/assets/img/2023/Orphelinat/image9.jpg",
    "/assets/img/2023/Orphelinat/image10.jpg",
]

const videos = [
    {
        vignette: "/assets/img/partners/1.jpg",
        src: "https://youtube.com/embed/pCfV5qsZns4?si=k0Wb_CM2LcmJhDkf"
    },
    {
        vignette: "/assets/img/partners/3.jpg",
        src: "https://youtube.com/embed/tX2pCwZo22A?si=BvfjLQ1mRqDr-Ahj"
    },
    {
        vignette: "/assets/img/partners/5.jpg",
        src: "https://youtube.com/embed/_vzFsStSaxM?si=TRchDCZGRV_lE6jm"
    },
    {
        vignette: "/assets/img/partners/2.jpg",
        src: "https://youtube.com/embed/b4JdjY2pKPY?si=a7ZunnLq9ZA4sPm0"
    },
    {
        vignette: "/assets/img/partners/6.jpg",
        src: "https://youtube.com/embed/auW-4M8LZLY?si=djmEbSZl5HLThD2b"
    },
    {
        vignette: "/assets/img/partners/7.jpg",
        src: "https://youtube.com/embed/kIaqpJIpDYE?si=DaggCxj_Pg8MBAXw"
    },
    {
        vignette: "/assets/img/partners/8.jpg",
        src: "https://youtube.com/embed/6h7S1MazSjo?si=OpZsCsNUR2BRXtE1"
    },
    {
        vignette: "/assets/img/partners/4.jpg",
        src: "https://youtube.com/embed/ToHd4efgk-8?si=7fc8k3GiqMfvIiPZ"
    }
]

const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: false,
    close: true,
    counter: true,
    bgOpacity: 0.6
}

function ActivityContainer() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    function shuffleArray(array) {
        const shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    return (
        <main>
            <Banner title={"Nos activités"} subtitle={"SAB FONDATION"} />

            <section id="about" class="about-area about-p pt-120 pb-50 p-relative">
                <div class="container">
                    <div class="row justify-content-center mb-4">
                        <div class="col-xl-10 col-lg-12">
                            <img src="/assets/img/2024.jpg" alt="photo_sab" />
                        </div>
                    </div><br />
                    <div class="row">
						<div class="col-lg-4">
                            <div class="about-content s-about-content pl-30">
                                <div class="about-title second-atitle">
                                    <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">SAB Fondation</span>
                                    <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Nos activités</h3>
                                </div>
                                <p>La Fondation SAB est une organisation à but lon lucratif qui s'engage depuis de nombreuses années pour améliorer les conditions de vie des enfants les plus vulnérables et eduquer les populations contre les maladies.
                                    Ses principales activités sont : Levée de fonds, visite d'orphelinats et marathon caritatif.
                                    Grâce à ses différentes activités, la Fondation poursuit ses efforts pour offrir un avenir meilleure à la communauté.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-8 pt-60">
                            <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color1">
                                    <i class="fal fa-money-bill"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#3C845C'}}>Levée de fonds</h5>
                                    <p>La fondation organise régulièrement des campagnes de collecte de dons auprès du grand public et des entreprises partenaires afin de financer ses différents programmes d'aide à l'enfance. Ces fonds permettent notamment de soutenir les orphelinats locaux et de mettre en place des initiatives éducatives et de développement.</p>
                                </div>
						    </div>
						    <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color2">
                                    <i class="far fa-users"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#B7C031'}}>Visite d'orphelinats</h5>
                                    <p>L'équipe de la fondation effectue des visites régulières dans les orphelinats de la région pour évaluer les besoins, rencontrer les enfants et les équipes en place, et s'assurer que les dons sont utilisés de manière optimale.</p>
                                </div>
                            </div>
						    <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color3">
                                    <i class="far fa-cogs"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#FDC828'}}>Marathon</h5>
                                    <p>Chaque année, la fondation organise un marathon solidaire qui rassemble des centaines de participants. Les fonds récoltés lors de cet événement sont entièrement reversés aux programmes d'aide à l'enfance en difficulté.</p>
                                </div>
                            </div>
                            {/* <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color4">
                                    <i class="fal fa-magic"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#E1DE1E'}}>SAB FOUNDATION,</h5>
                                    <p>qui fort de ses engagements sociaux rend l’accès gratuit au Salon pour les écoles, tout 
en déployant maintes activités de bienfaisance favorisant l’essor des couches défavorisées</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section id="team" class="team-area team-area-content p-relative pt-20 pb-120 fix">               
				<div class="circal1 item-zoom-inout"></div>
				<div class="circal2 item-zoom-inout"></div>
				<div class="circal3 item-zoom-inout"></div>
				<div class="circal4 item-zoom-inout"></div>
                <div class="container">
                    <div class="row">
						<div class="col-lg-5">
							<div class="wow fadeInLeft" data-animation="fadeInLeft" data-delay=".2s">
								<img src="/assets/img/2023/Orphelinat/image0.jpeg" alt="orphelinat_famille" />
							</div>
                        </div>
                        <div class="col-lg-7">
                            <div class="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s">                          
								<h2>Orphelinat Magnificat : une visite à impact positif</h2>    
								<span>23 Décembre 2023</span>
                                <p>Apporter un soutien émotionnel, fournir ressources et aliments, transmettre l’abécédaire alimentaire et bien-être, telle était l’objet de notre visite à l’orphelinat Magnificat le 23 décembre 2023. </p>
								<p>Nous transmettons notre profonde gratitude aux âmes de bonne volonté qui ont accepté donner des vivres, merci grandement à Madame la Directrice ainsi qu’au personnel qui nous ont permis d’éduquer les enfants quant aux bases de l’alimentation infantile suivie des gestes à même de favoriser leur croissance physique, mentale et émotionnelle.</p>
								<div class="social mt-50">
                                    <a href="tel:+237673045892"><i class="fab fas fa-phone-alt"></i></a>
                                    <a href="mailto:magnificatorg@yahoo.com"><i class="fab fas fa-envelope"></i></a>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" class="about-area about-p pt-20 pb-120 p-relative">
                <div className="container">
                    <div class="row align-items-center">                       
                        <div class="col-lg-6">
                            <div class="about-content s-about-content pl-30">
                                <div class="about-title second-atitle">
                                    <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Centre MAASEYA : pour l’autonomisation des enfants autistes</h2>
                                </div>
                                <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <p>Contribuer à changer le regard sur l’autisme en dépassant les préjugés et les idées reçues, tel est la raison d’être du Centre MAASEYA, qui accompagne les enfants sujets au spectre autistique d’atteindre leur pleine réalisation dans le respect des libertés fondamentales dues à tout être humain. Le SAB est allé à leur rencontre, une entrevue riche d’émotion et de perspective.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/maseya.jpg)"}}>
                                <div class="s-video-content">
                                    <div class="popup-video mb-50" onClick={() => handleShow()}>
                                        <i class="fas fa-play"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show">
                    <Modal show={show} size='lg' onHide={handleClose} style={{top: '30%'}}>
                        <Modal.Body>
                            <video width="auto" height="auto" controls autoPlay>
                                <source src={"/assets/video/Centre MASEYAA.mp4"} type="video/mp4" />
                            </video>
                        </Modal.Body>
                    </Modal>
                </div>
            </section>

            <section id="videos" class="portfolio-area p-relative pt-60 pb-120 fix">			
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <div class="section-title text-center mb-80">
                                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Tout en vidéos</h2>
                            </div>
                        </div>
                    </div>
                    
                    <Gallery options={options}>
                        <ResponsiveMasonry>
                            <Masonry columnsCount={3} gutter="20px">
                                {shuffleArray(videos).map((item, i) => (
                                    <Item
                                        original={item}
                                        thumbnail={item}
                                        width={800}
                                        height={590}
                                        key={i}
                                    >
                                        {({ ref, open }) => (
                                            <iframe 
                                                ref={ref} onClick={open} 
                                                class="popup-video cover-bg-img" 
                                                style={{height: 500}} src={item.src} 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                                allowfullscreen>
                                            </iframe>
                                        )}
                                    </Item>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Gallery>
                </div>
            </section>

            <section id="images" class="portfolio-area p-relative pt-60 pb-120 fix">			
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <div class="section-title text-center mb-80">
                                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Tout en images</h2>
                            </div>
                        </div>
                    </div>
                    <Gallery options={options}>
                        <ResponsiveMasonry>
                            <Masonry columnsCount={3} gutter="20px">
                                {shuffleArray(images).map((item, i) => (
                                    <Item
                                        original={item}
                                        thumbnail={item}
                                        width={800}
                                        height={590}
                                        key={i}
                                    >
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} src={item} alt={`Photo-` + i} style={{cursor:'pointer'}} />
                                        )}
                                    </Item>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Gallery>
                </div>
            </section>
        </main>
    )
}

export default ActivityContainer
