import React from 'react'
import Banner from './Banner'

function BlogContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Notre Blog" subtitle="Blog" />

            <section id="contact" class="pt-120 pb-120">
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10">
                            <div class="section-title text-center mb-80">
                                <span>Blog</span>
                                <h2>Bientôt disponible</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default BlogContainer
