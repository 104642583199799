import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactContainer from '../components/ContactContainer'

function Contact() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - SAB</title>
      </Helmet>

      <Header />

      <ContactContainer />

      <Footer />
    </React.Fragment>
  )
}

export default React.memo(Contact)