import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import ActivityContainer from '../components/ActivityContainer'
import Footer from '../components/Footer'

function Activity() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Nos Activités - SAB</title>
            </Helmet>

            <Header />

            <ActivityContainer />

            <Footer />
        </React.Fragment>
    )
}

export default Activity
