import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HomeContainer from '../components/HomeContainer'

function Home() {

  return (
    <React.Fragment>
      <Helmet>
        <title>Home - SAB</title>
      </Helmet>
      
      <Header />

      <HomeContainer />

      <Footer />

    </React.Fragment>
  )
}

export default React.memo(Home)
