import React from 'react'
import Banner from './Banner'
import BgVideo from './BgVideo'

function AProposContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Qui sommes-nous" subtitle="A Propos" />

            {/* Notre Vision */}
            <div class="event fix pt-120 pb-120">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-8">
                        <div class="section-title text-center mb-80">
                            <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">La Vision</span>
                            <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Combler le fossé alimentaire et améliorer l'accès au bien-être en Afrique</h3>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mb-4">
                    <div class="col-xl-10 col-lg-12">
                        <img src="/assets/img/2023/Photo-14.jpg" alt="photo_sab" />
                        {/* <img src="/assets/img/2024.jpg" alt="photo_sab" /> */}
                    </div>
                </div><br />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <p>
                            Qu'il s'agisse des exploitations familiales, de grandes entreprises de production alimentaire ou de 
                            l’industrie du Bien-être, des gouvernements et Institutions, ainsi que toutes les parties prenantes, 
                            l’accès alimentaire ainsi que les ressources favorisant la santé est une priorité absolue, la 
                            mondialisation couplée à l'hyperurbanisation ayant ouvert la voie à une hausse des produits 
                            alimentaires, des boissons et des repas pris à l'extérieur. Ces aliments bon marché et prêts à 
                            consommer sont souvent « ultra-transformés », riches en calories, en graisses, en sel et en sucre 
                            et pauvres en nutriments, sont conçus pour être hyper-appétissants et attrayants pour le 
                            consommateur, en plus de bénéficier d’une longue durée de conservation, ce qui leur octroie une 
                            plus-value aux yeux des consommateurs. Le marketing intensif de l'industrie - en particulier 
                            auprès des enfants avides de sucreries- a également contribué à la hausse de la consommation 
                            de ces types de produits, des innovations pourvues de conséquences. C’est ainsi que l’ONU 
                            dénombre d'un milliard de personnes obèses dans le monde- 650 millions d'adultes, 340 millions 
                            d'adolescents et 39 millions d'enfants, un nombre ne cesse d’augmenter, ouvrant la porte aux 
                            maladies cardiovasculaires, diabète, inflammations osseuses etc. en plus d’un fort impact sur la 
                            santé mentale.
                            </p>
                            <p>
                            Le Salon de l’Alimentation et du Bien-être pour l’Afrique, par un ballet d’experts savamment 
                            orchestré met en lumière les maillons d’une industrie au cœur des échanges nationaux et 
                            internationaux, doublée d’une vulgarisation d’habitude alimentaires simples et qualitatives 
                            auprès du public. Combler le fossé alimentaire et améliorer l’accès au bien-être en Afrique est 
                            notre raison d’être
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- about-area --> */}
            <section id="about" style={{marginTop: '-150px'}} class="about-area about-p pt-120 pb-50 p-relative">
                <div class="container">
                    <div class="row">
						<div class="col-lg-4">
                            <div class="about-content s-about-content pl-30">
                                <div class="about-title second-atitle">
								    <div class="text-outline">
										<span>A Propos</span>
									</div>
                                    <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">A Propos</span>
                                    <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Une Escale dédiée à la réflexion et au partage</h3>
                                </div>
                                <p>
                                Lieu de visibilité médiatique par excellence des Marques, le Salon de l’Alimentation et du Bien-Être 
                                célèbre la richesse d’un continent qui fédère et transcende un public qui découvrira avec 
                                émerveillement tous les secrets révélés durant ce moment d’effusion et de solidarité :
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-8 pt-60">
                            <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color1">
                                    <i class="fal fa-analytics"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#3C845C'}}>SAB EXPOSITIONS,</h5>
                                    <p>espaces dédiés à l’exposition et à la vente de produits et services locaux et 
internationaux de qualité premium.</p>
                                </div>
						    </div>
						    <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color2">
                                    <i class="far fa-users"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#B7C031'}}>SAB TALKS,</h5>
                                    <p>rencontres entre le public et des experts autour des thématiques de l’alimentation et du 
bien-être</p>
                                </div>
                            </div>
						    <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color3">
                                    <i class="far fa-cogs"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#FDC828'}}>SAB CHECKUP,</h5>
                                    <p>moment d’échange entre les visiteurs et des professionnels de santé et bien-être qui
après consultation leur prodigueront des informations à même de renforcer leur capital santé.
</p>
                                </div>
                            </div>
                            <div class="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <div class="icon color4">
                                    <i class="fal fa-magic"></i>
                                </div>
                                <div class="content">
                                    <h5 style={{color:'#E1DE1E'}}>SAB FOUNDATION,</h5>
                                    <p>qui fort de ses engagements sociaux rend l’accès gratuit au Salon pour les écoles, tout 
en déployant maintes activités de bienfaisance favorisant l’essor des couches défavorisées</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{marginTop: '-150px'}} class="inner-blog b-details-p pt-120 pb-140 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                <div class="container">
                    <div class="row">
                        <div className="col-lg-12">
                            <div class="details__content pb-50">
                                <blockquote>
                                    Accompagner ce salon est une évidence, tant par la qualité des idées que leur 
                                    déploiement, qui subliment les écosystèmes de l’alimentation et du bien-être. 
                                    <footer>- Nathalie MELOKOL EVINA NDO, Directrice de l’Ecole Pratique d’Agriculture de 
                                    BINGUELA.
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* PressBook Container */}

			{/* <!-- BgVideo-service --> */}
            <section class="sponsors services-bg fix" style={{marginTop: '-150px', marginBottom: '100px'}}>
                <div class="container">
                    <div class="row wow fadeInDown animated" 
                        data-animation="fadeInDown animated" 
                        data-delay=".2s"
                    >
                        <BgVideo />
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AProposContainer
