import React from 'react'

function PartnersContainer() {
    return (
        <div class="about-area about-p event fix pt-120 pb-120">
            <div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8">
					<div class="section-title text-center mb-80">
						<span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Nos invités de marque</span>
						<h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Un rendez-vous fédérant la fine fleur Made in Cameroon</h2>
					</div>
				</div>
			</div>
            <div className="container">
                <div class="row align-items-center">                      
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <h5 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s">Stéphane KAMWA, la Promesse d’Éternité</h5>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Disposant d’un service de communication 360°, Stéphane KAMWA, via son entreprise THEPLUG Group Sarl (Agence de communication et de production audiovisuelle), nous accompagne dans la conception et la réalisation de tous les supports de la deuxième édition de SAB Afrique. L'équipe THEPLUG Communication immortalisera également notre glorieuse épopée au travers d’images vibrantes et lumineuses, sublimées par un geste artistique empreint de douceur.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div style={{maxHeight: '300px', maxWidth: '300px'}}>
                            <img src="/assets/img/logo/theplug com.png" alt="ThePlug COM'" />
                        </div>
                    </div> 
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center"> 
                    <div class="col-lg-6">
                        <div class="s-video-wrap" style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/minks.jpg" alt="Mink's" />
                        </div>
                    </div>                      
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <h5 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s">MINK’S, le Chanteur de tous les Records</h5>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Celui qui fit son entrée au cœur de la scène musicale Camerounaise et Internationale avec le hit « Le gars là est laid » présidera au Marathon en tant qu’Invité de Marque.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center">                       
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <div class="text-outline wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <span>Partenaires</span>
                                </div>
                                <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Julia Samantha ÉDIMA, la Miss de Cœur </h3>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Celle qui siège au cœur des Miss Cameroun les plus aimées du public marquera l’événement par sa 
                                présence via sa fondation éponyme qui vient en aide aux personnes vulnérables.
                                .</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/samantha.jpg" alt="samantha" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center"> 
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/mmmh.jpg" alt="mmmh" height={400} />
                        </div>
                    </div>                      
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <h5 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s">Maman KAMITE, Artisan du Sublime</h5>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Trésor du patrimoine culinaire Camerounais, Vanessa KUISSU TABETH MBIALEU, connue sous la 
                                chaleureuse appellation « Maman KAMITE », est la Fondatrice de la plus grande adresse crêpière du pays, 
                                la légendaire « Mmmh La crêperie ». Elle déploiera dans un doux mélange de fierté et d’humilité les plus 
                                belles allégories de son vestiaire à l’orée du SAB AFRIQUE 2024.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center">                       
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <div class="text-outline wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <span>Partenaires</span>
                                </div>
                                <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">ANGE MBAYEN, Chef d’Œuvre de Raffinement et de Créativité</h3>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Référence unique en son genre, Ange MBAYEN est l’entrepreneure de tous les superlatifs. Productrice de 
                                la série à succès LES BOLDIES, elle met également sur pied BOLD MAKE UP, enseigne respectueuse de la 
                                carnation et créatrice d’éclat grâce à des formules à la pointe de l’innovation. Sa passion pour la lumière, 
                                fusionnée à son instinct puissant l’érige au pinacle des Artisans dédiés au Bien-être des consommateurs
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/bold.jpg" alt="bold" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center"> 
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/ndockbidi.jpg" alt="ndockbidi" />
                        </div>
                    </div>                      
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <div class="text-outline wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <span>Partenaires</span>
                                </div>
                                <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">NDOCK BIDI, l’Icône Culinaire</h3>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Parvenu au sommet par les vertus de son talent et sa grande persévérance, NDOCK BIDI animera le Live 
                                Cooking Show pour partager avec l’assistance sa recette diététique préférée.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center">                       
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <div class="text-outline wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <span>Partenaires</span>
                                </div>
                                <h3 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Joël KAMSU, l’Excellence du Voyage</h3>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Expériences uniques en osmose avec la nature, décors époustouflants, tables gastronomiques célébrant 
                                le Cameroun et son Esprit… Le tourisme du Bien-Être se décline à l’infini grâce à l’ingéniosité de Joël 
                                KAMSU, Founder de YENGA AFRICA qui nous promet euphorie et volupté à l’occasion de notre Rendez Vous
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/yengafrica3.png" alt="Yenga Africa" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div class="row align-items-center"> 
                    <div class="col-lg-6">
                        <div style={{maxHeight: '400px', maxWidth: '400px'}}>
                            <img src="/assets/img/logo/tara_sports.jpg" alt="Tara Sports" />
                        </div>
                    </div>                      
                    <div class="col-lg-6">
                        <div class="about-content s-about-content pl-30">
                            <div class="about-title second-atitle">
                                <h5 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s">Éliane NANA, l’Essence du Sport</h5>
                            </div>
                            <div class="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>A la tête de TARA SPORT, centre de marketing sportif, Consulting, Événementiel et Gestion de carrière, 
                                Éliane NANA déploie chaque année le Marathon International de Douala, accueillant des athlètes de haut 
                                niveau venus des quatre coins du globe. Elle partagera ses acquis incomparables lors du Semi-Marathon
                                agendé par ses soins en cette deuxième édition du SAB AFRIQUE.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnersContainer
