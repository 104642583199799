import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import GalerieContainer from '../components/GalerieContainer'
import Footer from '../components/Footer'
import '../../node_modules/photoswipe/dist/photoswipe.css'

function Galerie() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Galerie - SAB</title>
            </Helmet>

            <Header />

            <GalerieContainer />

            <Footer />
        </React.Fragment>
    )
}

export default Galerie
