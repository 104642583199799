import React from 'react'

function Footer() {
  return (
    <footer class="footer-bg footer-p" style={{backgroundImage: "url(../../public/assets/img/footer_bg_img.jpg)", backgroundSize: "cover"}}>
          
            <div class="footer-top">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-xl-12 col-lg-12 col-sm-12 text-center">
                            <div class="footer-widget pt-120 mb-30">
                                <div class="logo mb-35">
                                    <a href="#"><img src="/assets/img/logo/logo5.png" width={150} height={50} alt="logo" /></a>
                                </div>
                                <div class="footer-text mb-20">
                                    <p>Envie d'en savoir plus sur nous? Suivez-nous sur les réseaux sociaux pour avoir notre actualité au quotidien.</p>
                                </div>
                                <div class="footer-social">                                    
                                    <a target='_blank' href="https://www.facebook.com/sab.cameroun"><i class="fab fa-facebook-f"></i></a>
                                    <a target='_blank' href="https://x.com/sab_afrique"><i class="fab fa-twitter"></i></a>
                                    <a target='_blank' href="https://www.instagram.com/sab.afrique/"><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="copyright-wrap pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-12">						
                            <div class="copyright-text text-center">
								<div class="footer-link">
                                    <ul>
                                        <li>
                                            <a href="/politique-confidentialite" style={{color: "#B7C031"}}>Politique de confidentialité</a>
                                        </li>
                                        <li>
                                            <a href="/conditions-generales" style={{color: "#B7C031"}}>Conditions générales</a>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>&copy; 2024 SAB-DFIT</li>
                                        <li>Designé par 
                                            <a target='_blank' href="https://www.comtheplug.com/"> THEPLUG COM'</a>
                                        </li>
                                    </ul>
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Footer
