import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import BlogContainer from '../components/BlogContainer'

function Blog() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Blog - SAB</title>
            </Helmet>

            <Header />

            <BlogContainer />

            <Footer />
        </React.Fragment>
    )
}

export default Blog
