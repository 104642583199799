import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Banner from './Banner'
import { Gallery, Item } from 'react-photoswipe-gallery'

const items = [
    {
        src: "/assets/img/2023/Photo.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-2.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-3.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-4.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-5.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-6.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-7.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-8.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-9.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-10.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-11.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-12.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-13.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-14.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-15.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-16.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-17.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-18.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-19.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-20.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-21.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-22.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-23.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-24.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-25.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-26.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-27.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-28.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-29.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-30.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-31.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-32.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-33.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-34.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-35.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-36.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-37.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-38.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-39.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-40.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-41.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-42.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-43.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-44.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-45.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-46.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-47.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-48.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-49.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-50.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-51.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-52.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-53.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-54.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-55.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-56.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-57.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-58.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-59.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-60.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-61.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-62.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-63.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-64.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-65.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-66.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-67.jpg",
        height: 533,
        width: 800
    }
]

function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: true,
    close: true,
    loop: true,
    counter: true,
    bgOpacity: 0.6
}

function GalerieContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Galerie" subtitle="Galerie" />

            {/* <!-- team-area --> */}
            <section id="team" class="portfolio-area p-relative pt-120 pb-120 fix">
                <div class="section-t team-t paroller" 
                    data-paroller-factor="0.15" 
                    data-paroller-factor-lg="0.15" 
                    data-paroller-factor-md="0.15" 
                    data-paroller-factor-sm="0.15" 
                    data-paroller-type="foreground" 
                    data-paroller-direction="horizontal"
                ><h2>Galerie</h2></div>				
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <div class="section-title text-center mb-80">
                                <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">galerie</span>
                                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Notre album</h2>
                            </div>
                        </div>
                    </div>
                    <Gallery options={options}>
                        <ResponsiveMasonry>
                            <Masonry columnsCount={3} gutter="10px">
                                {shuffleArray(items).map((item, i) => (
                                    <Item
                                        original={item.src}
                                        thumbnail={item.src}
                                        width={item.width}
                                        height={item.height}
                                        key={i}
                                    >
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} src={item.src} alt={`Photo-` + i} style={{cursor:'pointer'}} />
                                        )}
                                    </Item>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Gallery>
                </div>
            </section>
        </main>
    )
}

export default GalerieContainer
